<template>
	<div class="app-container" v-loading="loading">
		<div class="titlebox">
			<el-button size="medium" type="text" @click="$router.go(-1)">返回</el-button>
			<div class="title">{{ $route.query.year }}年奖金发放预览</div>
		</div>
		<section class="sections-container">
			<el-form :model="searchForm" inline ref="form" class="searchForm" size="small">
				<el-form-item label="姓名">
					<el-input v-model="searchForm.staffName" clearable placeholder="输入姓名" style="width: 160px" />
				</el-form-item>
				<el-form-item label="部门">
					<treeselect
						:props="depProps"
						:options="deps"
						:value="searchForm.department"
						:clearable="true"
						:accordion="true"
						:level="searchForm.department"
						@getValue="_getValue($event)"
						style="width: 160px"
					/>
				</el-form-item>
				<el-button type="primary" size="small" @click="_getList(1)" icon="el-icon-search">查询</el-button>
				<section style="float: right; margin-bottom: 20px">
					<el-button type="primary" size="small" icon="el-icon-check" @click="_save" v-if="grantType != 1">保存</el-button>
					<el-button type="primary" size="small" icon="el-icon-document-checked" @click="_grant" v-if="grantType != 1">确定发放</el-button>
				</section>
			</el-form>
		</section>
		<section class="sections-container">
			<el-table
				class="moryeTable"
				:data="showList"
				border
				:summary-method="getSummaries"
				:header-row-style="{ 'background-color': '#F0F8FF!important' }"
				show-summary
			>
				<!--<el-table-column
                fixed
                type="index"
                label="序号"
                show-overflow-tooltip
                width="100"
                align="center">
            </el-table-column>!-->
				<el-table-column
					v-for="(col, i) in columns"
					:prop="col.prop"
					:key="i"
					:fixed="i < 2"
					:label="col.label"
					show-overflow-tooltip
					width="150"
					align="center"
				>
					<!-- <template v-slot="{ row }" v-if="col.prop != 'staffName' && col.prop != 'orgName'&& col.prop != 'jobName' && col.prop != 'adjustBonusAmount'">
                    {{row[col.prop] | rounding}}
                </template> -->
					<template v-slot="{ row }" v-if="col.prop == 'adjustBonusAmount'">
						<el-input
							onkeyup="value=value.replace(/[^\d^\.^\-]/g,'')"
							v-model="row.adjustBonusAmount"
							placeholder="请输入"
							v-if="grantType != 1"
							:readonly="editStatus"
						></el-input>
						<span v-else>{{ `${(row.adjustBonusAmount >= 0 ? '+' : '') + row.adjustBonusAmount}` }}</span>
					</template>
					<template v-slot="{ row }" v-else-if="col.prop == 'adjustJobRatio'">
						<el-input
							onkeyup="value=value.replace(/[^\d^\.^\-]/g,'')"
							v-model="row.adjustJobRatio"
							placeholder="请输入"
							v-if="grantType != 1"
							:readonly="editStatus"
						></el-input>
						<span v-else>{{ row.adjustJobRatio }}</span>
					</template>
					<template v-slot="{ row }" v-else-if="col.prop == 'remark'">
						<el-input v-model="row.remark" placeholder="请输入" v-if="grantType != 1" :readonly="editStatus"></el-input>
						<span v-else>{{ row.remark }}</span>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				v-if="searchForm.totalNum > 0"
				@size-change="_handleSizeChange"
				@current-change="_handleCurrentChange"
				:current-page="searchForm.pageNo"
				:page-sizes="[20, 40, 80, 100]"
				:page-size="searchForm.pageSize"
				style="text-align: right; margin-top: 40px"
				layout="total, sizes, prev, pager, next, jumper"
				:total="searchForm.totalNum"
			>
			</el-pagination>
		</section>
	</div>
</template>

<script>
import treeselect from '@/components/treeSelect/treeSelect';
import { bonusStaffList, bounsAdjust, bounsGrant } from '@/api/remuneration';
export default {
	components: { treeselect },
	data() {
		return {
			searchForm: {
				year: this.$route.query.year,
				staffName: '',
				orgId: '',
				pageNo: 1,
				pageSize: 20,
				totalNum: 0
			},
			deps: this.$store.state.setData.depsList,
			depProps: {
				value: 'id',
				label: 'orgName',
				children: 'children'
			},
			loading: false,
			grantType: this.$route.query.grantType,
			editStatus: false,
			showList: [],
			columns: [
				{ label: '姓名', prop: 'staffName' },
				{ label: '部门', prop: 'orgName' },
				{ label: '部门组织系数', prop: 'orgRatio' },
				{ label: '部门考核系数', prop: 'assessRatio' },
				{ label: '岗位', prop: 'jobName' },
				{ label: '原岗位价值系数', prop: 'jobRatio' },
				{ label: '调整岗位价值系数', prop: 'adjustJobRatio' },
				{ label: '个人考核系数', prop: 'userAssessRatio' },
				{ label: '个人考核得分', prop: 'assessScore' },
				{ label: '个人奖金系数', prop: 'bonusSratio' },
				{ label: '预测年终奖', prop: 'predictionBonusAmount' },
				{ label: '调整奖金', prop: 'adjustBonusAmount' },
				{ label: '实发年终奖金', prop: 'bonusAmount' },
				{ label: '调整备注', prop: 'remark' }
			],
			list: [],
			labels: [],
			setList: {}
		};
	},
	methods: {
		_getValue(eve) {
			this.searchForm.orgId = eve ? eve.id : '';
		},
		_getList(page) {
			page ? (this.searchForm.pageNo = page) : '';
			this.loading = true;
			bonusStaffList(this.searchForm).then((res) => {
				this.loading = false;
				this.searchForm.totalNum = res.totalNum;
				this.list = res.list;
				this.showList = res.list;
				// let _list = [];
				// this.labels = [...new Set(res.list.map(item => item.a))];
				// for(let val of this.labels){
				//     let _l = [];
				//     for(let key in this.list){
				//         if(this.list[key].a == val){
				//             this.list[key].index = Number(key)+1;
				//             _l.push(this.list[key]);
				//         }
				//     }
				//     _l[0].type =1;
				//     _l[0].rowLength = _l.length;
				//     const _obj = {
				//         index : '部门合计',
				//         orgRatio : this._returnSummaries(_l,'orgRatio'),
				//         assessScore : this._returnSummaries(_l,'assessScore'),
				//         bonusSratio : this._returnSummaries(_l,'bonusSratio'),
				//         predictionBonusAmount : this._returnSummaries(_l,'predictionBonusAmount'),
				//         adjustBonusAmount : this._returnSummaries(_l,'adjustBonusAmount',true),
				//         bonusAmount : this._returnSummaries(_l,'bonusAmount'),
				//         sum : 1
				//     }
				//     _list = _list.concat(_l);
				//     _list.push(_obj)
				// }
				// this.showList = _list;
			});
		},
		_handleSizeChange(val) {
			this.searchForm.pageSize = val;
			this._getList();
		},
		_handleCurrentChange(val) {
			this.searchForm.pageNo = val;
			this._getList();
		},
		_save() {
			const _list = [];

			for (const key in this.showList) {
				if (!this.showList[key].sum) {
					if (!this.showList[key].adjustBonusAmount) {
						this.$message.success('请补全调整奖金');
						return false;
					}
					_list.push({
						id: this.showList[key].id,
						adjustBonusAmount: this.showList[key].adjustBonusAmount,
						adjustJobRatio: this.showList[key].adjustJobRatio,
						remark: this.showList[key].remark
					});
				}
			}
			this.$confirm('是否保存当前设置', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.loading = true;
					bounsAdjust({ updateList: _list, year: this.searchForm.year }).then((res) => {
						this.loading = false;
						if (res.code == 0) {
							this._getList();
							this.$message.success('保存成功');
						}
					});
				})
				.catch(() => {});
		},
		_grant() {
			this.$confirm('是否发放年终奖?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.loading = true;
					bounsGrant({ year: this.searchForm.year }).then((res) => {
						this.loading = false;
						if (res.code == 0) {
							this.grantType = 1;
							this.$message.success('保存成功');
						}
					});
				})
				.catch(() => {});
		},
		getSummaries(param) {
			const { columns } = param;
			const sums = [];

			columns.forEach((column, index) => {
				if (index === 0) {
					sums[index] = '总合计';
					return;
				}
				const values = this.list.map((item) => Number(item[column.property]));

				if (index == 11 || index == 13) {
					sums[index] = values
						.reduce((prev, curr) => {
							const value = Number(curr);

							if (!isNaN(value)) {
								return prev + curr;
							}
							return prev;
						}, 0)
						.toFixed(2);
				}
			});
			return sums;
		},
		_returnSummaries(list, prop) {
			const _values = [...list].map((item) => Number(item[prop]));
			const _returnValue = _values.reduce((prev, curr) => prev + curr, 0);

			return _returnValue;
		},
		_toFixed(num, num2) {
			return Number(num).toFixed(num2);
		}
	},
	mounted() {
		this._getList();
	},
	filters: {
		rounding(value) {
			return Number(value).toFixed(2);
		}
	}
};
</script>
<style lang="scss" scoped>
::v-deep.el-form-item__label {
	font-weight: normal;
}
.sections-container {
	overflow: hidden;
	margin-bottom: 20px;
	.title-icon {
		font-size: 16px;
		margin: 0 0 20px 30px;
		&:before {
			display: inline-block;
			width: 8px;
			height: 18px;
			background: #498df0;
			content: ' ';
			border-radius: 5px;
			vertical-align: top;
			margin-right: 5px;
		}
	}
}
.sections-container {
	border-radius: 4px;
	padding: 20px 30px 0;
	background-color: #fff;
}
.titlebox {
	text-align: center;
	line-height: 17px;
	background: #fff;
	font: bold 18px/25px 'Microsoft YaHei';
	padding: 14px 40px;
	margin-bottom: 20px;
	> .el-button {
		float: left;
		color: #484848;
		transform: translateY(-8px);
		font-size: 16px;
	}
}
.moryeTable {
	::v-deep.el-table th,
	.el-table tr {
		background-color: #f0f8ff;
	}
}
</style>
<style lang="scss" scoped>
::v-deep.el-table th,
.el-table tr {
	background-color: #f0f8ff;
}
</style>
